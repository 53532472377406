<script>


export default {
    name: "AdministradorDeVacantes",
    data() {
        return {
            listaDeVacantes: [],
        }
    },
    mounted() {
        this.getListaDeVacantes();
    },
    methods: {
        async getListaDeVacantes() {
            const response = await this.$store.getters.fetchGet({ path: `Vacante/empresa/${this.$store.getters.userLoggedIn.empresa.id}` })
            this.listaDeVacantes = await response.json();
        },
        async toggleState(vacante) {
            const id = vacante.id
            vacante.estado = null
            const response = await this.$store.getters.fetchPut({ path: `vacante/HiddenToggle/${id}` })
            const result = await response.json()
            vacante.estado = result.estado
        },
    }
}


</script>

<template>
    <b-container fluid>
        <b-row>
            <b-col sm="12">
                <card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title"><b>LISTA DE VACANTES</b></h4>
                    </template>
                    <div class="text-right px-4 mt-3">
                        <router-link class="mx-1 btn btn-outline-success" :to="{ name: 'NuevaVacante'}">
                            <i class="fas fa-plus"></i> Crear vacante
                        </router-link>
                    </div>
                    <div class="table-responsive px-4 mt-3">
                        <table id="datatable_cargos"
                            class="table table-bordered table-responsive-md table-striped text-center">
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Vacante</th>
                                    <th>Pre-selección</th>
                                    <th>Selección</th>
                                    <th>Vinculación</th>
                                    <th>Estado</th>
                                    <th>Opciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in listaDeVacantes" :key="item.id">
                                    <td>{{ item.cargo.nombre }}</td>
                                    <td>
                                        <router-link class="btn btn-primary btn-sm" :to="{ name: 'InformacionVacante', params: { id: item.id}}" >
                                            Ver más
                                        </router-link>
                                    </td>
                                    
                                    <td>
                                        <router-link class="btn btn-primary btn-sm" :to="{ name: 'ProcesoDePreseleccion', params: { vacanteId: item.id}}" >
                                            Ver más
                                        </router-link>
                                    </td>
                                    <td>
                                        <router-link class="btn btn-primary btn-sm" :to="{ name: 'ProcesoDeSeleccion', params: { vacanteId: item.id}}" >
                                            Ver más
                                        </router-link>
                                    </td>
                                    <td>
                                        <router-link class="btn btn-primary btn-sm" :to="{ name: 'VinculacionDePostulantes', params: { vacanteId: item.id}}" >
                                            Ver más
                                        </router-link>
                                    </td>
                                    <td>
                                        <div style="min-width: 75px; display: flex; justify-content: center;">
                                            <div @click.prevent="toggleState(item)" class="pointer"
                                                v-if="item.estado != null">
                                                <span v-if="item.estado == 1" class="badge badge-success">Activo</span>
                                                <span v-else class="badge badge-danger">Inactivo</span>
                                            </div>
                                            <div v-else>
                                                <span>
                                                    <i class="fas fa-spinner fa-spin"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="options">
                                        <router-link :to="{ name: 'EditarVacante', params: { id: item.id } }">
                                            <span>
                                                <i data-toggle="tooltip" data-placement="top" title="Editar vacante"
                                                    class="pointer fas fa-solid fa-pen px-1 text-success"></i>
                                            </span>
                                        </router-link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </card>
            </b-col>
        </b-row>
    </b-container>
</template>

<style scoped>
.options {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>